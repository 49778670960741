const init = () => {
    // top bar active item
    document.querySelector("#nav-item-index").classList.add("active");
    document.querySelector("#nav-item-contact").classList.remove("active");

    // contact btn
    document
        .querySelector("#contact-btn")
        .addEventListener("click", (event) => {
            event.preventDefault();
            window.location.replace("/contact.html");
        });
};

init();
